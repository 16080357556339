body {
  margin: 0;
  font-family: 'Raleway';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Last view*/
.last-view {
  padding-bottom: 35px;
}

@media (max-width: 425.9px) {
  .last-view {
    padding-bottom: 80px !important;
  }
}

@media (max-width: 293.9px) {
  .last-view {
    padding-bottom: 120px !important;
  }
}

/* Tienda estilos generales para todos los productos */
.product-header {
  background-image: linear-gradient(#2699FB, #ffff);
}

/* input search */
.product-header .input-group-append {
  background-color: #ffff;
  border-radius: 0 5px 5px 0;
}

.product-header .has-search .form-control {
  padding-left: 2.375rem;
}

/*Ratings*/
.ratings-product i {
  font-size: 25px;
}

.ratings-product .far {
  color: #FFD700 !important
}

.reviews-rating-product-cte .ratings-product i {
  font-size: 18px;
}


/*Producto*/
.btn-add-cart {
  background-color: #BCE0FD;
  color: #2699FB;
}

.btn-add-cart:hover {
  background-color: #b4d6f3;
  color: #2699FB;
}

.product-mobile {
  display: none;
}

@media (max-width: 767.9px) {
  .product-desktop {
    display: none;
  }

  .product-mobile {
    display: block;
  }

  .share {
    margin-left: 20px;
  }
}

@media (max-width: 231.9px) {
  .cte-review .d-flex {
    justify-content: center !important;
  }
}

/* Fin tienda */

/* Logo de whatsapp en sucursales */
@media (max-width: 991.5px) {
  .logo-whatsapp img {
    width: 60% !important;
  }
}

@media (max-width: 767.5px) {
  .logo-whatsapp img {
    width: 80% !important;
  }
}

@media (max-width: 425.9px) {
  .logo-whatsapp img {
    width: 100% !important;
  }
}


/*MUI datagrid*/
.css-levciy-MuiTablePagination-displayedRows {
  margin: auto !important;
}

#launcher-frame {
  z-index: 1 !important;
}

@media (max-width: 425.9px) {
  #launcher-frame {
    bottom: 60px !important;
  }
}

@media (max-width: 991.5px) {
  .dummy-class {
    flex-wrap: wrap !important;
    justify-content: space-around !important;
    padding-top: 10px !important;
  }
}

@media (max-width: 375.5px) {
  .dummy-class h6 {
    font-size: 18px;
  }
}

@media (max-width: 319.5px) {
  .dummy-class h6 {
    font-size: 16px;
  }
}

.ql-editor {
  min-height: 200px;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-top: 0 !important;
  margin: auto !important;
}

.chattigo-widget-trigger {
  left: 24px !important;
}